import { PostReportReason } from "@/lib/constants/post";
import axiosInstance from "../axios/axiosInstance";

export const createPost = async (fileName: string) => {
  try {
    const postResponse = await axiosInstance.post(`/post`, {
      fileName: fileName,
    });
    return { postResponse, error: null };
  } catch (error) {
    console.log(error);
    return { postResponse: null, error };
  }
};

export const createGif = async (fileName: string) => {
  try {
    const postResponse = await axiosInstance.post(`/post/gif`, {
      fileName: fileName,
    });
    return { postResponse, error: null };
  } catch (error) {
    console.log(error);
    return { postResponse: null, error };
  }
};

export const reportPost = async (
  postId: string,
  reason: PostReportReason,
  message: string,
  otherReason?: string,
) => {
  try {
    const postResponse = await axiosInstance.post(`/post/${postId}/report`, {
      reason,
      message,
      otherReason,
    });
    return { postResponse, error: null };
  } catch (error) {
    console.log(error);
    return { postResponse: null, error };
  }
};

export const likePost = async (postId: string) => {
  if (!postId) {
    return;
  }
  try {
    const likeResponse = await axiosInstance.post(`/post/${postId}/like`, {});
    // console.log(likeResponse);
    return { likeResponse, error: null };
  } catch (error) {
    console.log(error);
    return { likeResponse: null, error };
  }
};

export const unlikePost = async (postId: string) => {
  if (!postId) {
    return;
  }
  try {
    const unlikeResponse = await axiosInstance.post(`/post/${postId}/unlike`, {});
    return { unlikeResponse, error: null };
  } catch (error) {
    console.log(error);
    return { unlikeResponse: null, error };
  }
};

export const getPost = async (postId: string) => {
  try {
    const postDetailsResponse = await axiosInstance.get(`/post/${postId}`);

    // const cacheDuration = 15 * 24 * 60 * 60;
    // postDetailsResponse.headers['Cache-Control'] = `s-maxage=${cacheDuration}, stale-while-revalidate`;

    // console.log(postDetailsResponse);

    return { postDetailsResponse, error: null };
  } catch (error) {
    console.log(error);
    return { postDetailsResponse: null, error };
  }
};

export const hidePost = async (postId: string) => {
  try {
    const hideResponse = await axiosInstance.post(`/post/${postId}/hide`, {});
    return { hideResponse, error: null };
  } catch (error) {
    console.log(error);
    return { hideResponse: null, error };
  }
};

export const getPostPaginationCache = async () => {
  try {
    const paginationCacheResponse = await axiosInstance.get(`/post/cache/pagination`);
    // console.log(paginationCacheResponse);
    return { paginationCacheResponse, error: null };
  } catch (error) {
    console.log(error);
    return { paginationCacheResponse: null, error };
  }
};

export const getPostPageCount = async () => {
  try {
    const pageCountResponse = await axiosInstance.get(`/post/page/count`);
    return { pageCountResponse, error: null };
  } catch (error) {
    console.log(error);
    return { pageCountResponse: null, error };
  }
};

export const getPostByPage = async (pageNumber: number) => {
  try {
    const pagePostsResponse = await axiosInstance.get(`/post/page/${pageNumber}`);
    return { pagePostsResponse, error: null };
  } catch (error) {
    console.log(error);
    return { pagePostsResponse: null, error };
  }
};

export const getPostsByTagIds = async (
  tagIds: string[],
  limit: number = 20,
  timespan: number = 0,
  images: number = 1,
  views: number = 1,
  likes: number = 1,
  author: number = 1,
  generator: number = 1,
  generatorId: string,
) => {
  try {
    const tagIdsString = tagIds.join(",");
    const params = {
      limit: limit.toString(),
      timespan: timespan.toString(),
      tagId: tagIdsString,
      images: images.toString(),
      views: views.toString(),
      likes: likes.toString(),
      author: author.toString(),
      generator: generator.toString(),
      generatorId,
    };

    const config = {
      params: params,
    };

    const response = await axiosInstance.get(`/post/collection`, config);

    // console.log(response)
    return { response, error: null };
  } catch (error) {
    console.log(error);
    return { response: null, error };
  }
};

export const getRelatedPosts = async (postId: string) => {
  try {
    const relatedPostsResponse = await axiosInstance.get(`/post/related/${postId}`);

    // const cacheDuration = 15 * 24 * 60 * 60;
    // relatedPostsResponse.headers[
    //   "Cache-Control"
    // ] = `s-maxage=${cacheDuration}, stale-while-revalidate`;

    return { relatedPostsResponse, error: null };
  } catch (error) {
    return { relatedPostsResponse: null, error };
  }
};

export const getPostTags = async (postId: string, style: string) => {
  try {
    const postTagsResponse = await axiosInstance.get(`/post/tags/${postId}?style=${style}`);
    return { postTagsResponse, error: null };
  } catch (error) {
    // console.log(error);
    return { postTagsResponse: null, error };
  }
};
