"use client";
import Link from "next/link";
import { getLocalizedPath } from "./formatURL";
import { useGlobalContext } from "../../app/context/store";
import { usePathname } from "next/navigation";

interface CreateButtonProps {
  buttonText: string;
  upgradeText: string;
}

const CreateButton = ({ buttonText, upgradeText }: CreateButtonProps) => {
  const { user } = useGlobalContext();
  const pathname = usePathname();

  return (
    <div className="flex flex-row">
      <Link
        prefetch={false}
        href={getLocalizedPath("create")}
        className="rounded-full bg-[#DD0772] px-4 py-2 text-sm font-bold text-white shadow-2xl hover:brightness-105"
      >
        {buttonText}
      </Link>
      {!pathname.includes("blog") && (
        <Link
          prefetch={false}
          href={getLocalizedPath("upgrade") + "?ref=create"}
          className={`${
            user.membership && user?.membership.level === 1 ? "hidden" : "hidden sm:block"
          } ml-4 rounded-full bg-[#FFCC18] px-4 py-2 text-sm font-bold text-black shadow-2xl hover:brightness-105`}
        >
          {upgradeText}
        </Link>
      )}
    </div>
  );
};

export default CreateButton;
