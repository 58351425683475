"use client";

import { useGlobalContext } from "@/app/context/store";
import { useSignInModal } from "@/components/layout/sign-in-modal";
import { addPostsToAlbums, createAlbum, getUserAlbums } from "@/lib/api/album";
import { FolderPlus } from "lucide-react";
import Image from "next/image";
import { useEffect, useState } from "react";
import { Album } from "types/album";
import Modal from "@/components/shared/modal";
import { Dispatch, SetStateAction } from "react";

interface AddToAlbumModalProps {
  showAddToAlbumModal: boolean;
  setShowAddToAlbumModal: Dispatch<SetStateAction<boolean>>;
  postIds: string[];
  translations: any;
  signInModalTranslations: any;
}

export default function AddToAlbumModal({
  showAddToAlbumModal,
  setShowAddToAlbumModal,
  postIds,
  translations,
  signInModalTranslations,
}: AddToAlbumModalProps) {
  const { user } = useGlobalContext();
  const { SignInModal, setShowSignInModal } = useSignInModal(signInModalTranslations);
  const [albums, setAlbums] = useState<Album[]>([]);
  const [selectedAlbumIds, setSelectedAlbumIds] = useState<string[]>([]);
  const [newAlbumName, setNewAlbumName] = useState("");
  const [newAlbumDescription, setNewAlbumDescription] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showCreateForm, setShowCreateForm] = useState(false);

  useEffect(() => {
    if (showAddToAlbumModal && user._id === "none") {
      setShowAddToAlbumModal(false);
      setShowSignInModal(true);
      return;
    }

    const fetchAlbums = async () => {
      if (user._id !== "none") {
        try {
          setIsLoading(true);
          const albums = await getUserAlbums(user._id, postIds);
          setAlbums(albums);
        } catch (error) {
          console.error("Error fetching albums:", error);
          setAlbums([]);
        } finally {
          setIsLoading(false);
        }
      }
    };
    if (showAddToAlbumModal) {
      fetchAlbums();
    }
  }, [user._id, showAddToAlbumModal, setShowAddToAlbumModal, setShowSignInModal, postIds]);

  const handleAddToAlbum = async () => {
    if (selectedAlbumIds.length === 0) return;
    setIsLoading(true);
    try {
      await addPostsToAlbums(selectedAlbumIds, postIds);
      setAlbums((prevAlbums) =>
        prevAlbums.map((album) =>
          selectedAlbumIds.includes(album._id) ? { ...album, containsPosts: true } : album,
        ),
      );
      setSelectedAlbumIds([]);
    } catch (error: any) {
      console.error("Error adding posts to albums:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCreateAlbum = async () => {
    if (!newAlbumName) return;
    setIsLoading(true);
    try {
      const newAlbum = await createAlbum(newAlbumName, newAlbumDescription, postIds);
      setAlbums([...albums, { ...newAlbum, containsPosts: true }]);
      setShowCreateForm(false);
      setNewAlbumName("");
      setNewAlbumDescription("");
    } catch (error: any) {
      console.error("Error creating album:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal showModal={showAddToAlbumModal} setShowModal={setShowAddToAlbumModal}>
      <div className="w-full overflow-hidden border-0 border-[#3C3C3C] bg-crp-grey shadow-xl sm:border-[0.5px] md:max-w-md md:rounded-2xl">
        <div className="flex flex-wrap items-center justify-center border-b-[0.5px] border-grey-outline bg-[#202124] px-6 py-6 pt-6 text-center font-bold text-white md:px-16">
          <h3 className="text-base font-medium leading-6 text-white sm:text-lg">
            {translations.addToAlbum}
          </h3>
        </div>
        <div className="my-4">
          <div className="rounded-lg bg-crp-grey p-4">
            <div className="max-h-[300px] space-y-2.5 overflow-y-auto pr-2 [&::-webkit-scrollbar-thumb:hover]:bg-[#4A4A4A] [&::-webkit-scrollbar-thumb]:rounded-full [&::-webkit-scrollbar-thumb]:bg-[#3C3C3C] [&::-webkit-scrollbar-track]:rounded-full [&::-webkit-scrollbar-track]:bg-[#202124] [&::-webkit-scrollbar]:w-2">
              {!showCreateForm && !isLoading && (
                <div
                  onClick={() => setShowCreateForm(true)}
                  className="cursor-pointer rounded-lg border-2 border-dashed border-[#3C3C3C] bg-[#202124]/50 p-3 transition-all duration-200 hover:border-[#DD0772]/50 hover:bg-[#2B2D31]"
                >
                  <div className="flex items-center text-white">
                    <FolderPlus className="mr-2 h-4 w-4" />
                    <p className="font-medium">{translations.createAlbum}</p>
                  </div>
                </div>
              )}
              {showCreateForm && (
                <div className="space-y-2.5 rounded-lg border border-[#DD0772] bg-[#DD0772]/10 p-3">
                  <input
                    type="text"
                    placeholder={translations.albumName}
                    value={newAlbumName}
                    onChange={(e) => setNewAlbumName(e.target.value)}
                    className="w-full rounded-lg border border-[#3C3C3C] bg-[#202124] px-4 py-2.5 text-white transition-all duration-200 focus:border-[#DD0772] focus:outline-none focus:ring-0"
                  />
                  <textarea
                    placeholder={translations.albumDescription}
                    value={newAlbumDescription}
                    onChange={(e) => setNewAlbumDescription(e.target.value)}
                    className="min-h-[100px] w-full resize-none rounded-lg border border-[#3C3C3C] bg-[#202124] px-4 py-2.5 text-white transition-all duration-200 focus:border-[#DD0772] focus:outline-none focus:ring-0"
                  />
                  <div className="flex justify-end gap-2">
                    <button
                      onClick={() => {
                        setShowCreateForm(false);
                        setNewAlbumName("");
                        setNewAlbumDescription("");
                      }}
                      className="rounded-lg bg-[#202124] px-3 py-1.5 text-sm text-white transition-all duration-200 hover:bg-[#2B2D31]"
                    >
                      {translations.cancel}
                    </button>
                    <button
                      onClick={handleCreateAlbum}
                      disabled={!newAlbumName || isLoading}
                      className="inline-flex items-center rounded-lg bg-[#DD0772] px-3 py-1.5 text-sm text-white transition-all duration-200 hover:bg-[#DD0772]/90 disabled:cursor-not-allowed disabled:opacity-50"
                    >
                      {isLoading && (
                        <div className="mr-2 h-3.5 w-3.5 animate-spin rounded-full border-[1.5px] border-white/20 border-t-white" />
                      )}
                      {translations.create}
                    </button>
                  </div>
                </div>
              )}
              {isLoading && !showCreateForm && (
                <div className="flex rounded-lg border border-[#3C3C3C] bg-[#202124]">
                  <Image
                    src={"https://cdn6.imgpog.com/loadingGIF.webp"}
                    width={50}
                    height={50}
                    alt="Loading"
                    className="mx-auto"
                    unoptimized
                  />
                </div>
              )}
              {!isLoading &&
                albums.map((album) => {
                  return (
                    <div
                      key={album._id}
                      onClick={() => {
                        if (!album.containsPosts) {
                          if (selectedAlbumIds.includes(album._id)) {
                            setSelectedAlbumIds(selectedAlbumIds.filter((id) => id !== album._id));
                          } else {
                            setSelectedAlbumIds([...selectedAlbumIds, album._id]);
                          }
                        }
                      }}
                      className={`cursor-pointer rounded-lg border p-3 transition-all duration-200 ${
                        album.containsPosts
                          ? "cursor-not-allowed border-[#DD0772] bg-[#DD0772]/10"
                          : selectedAlbumIds.includes(album._id)
                          ? "border-[#DD0772] bg-[#DD0772]/10"
                          : "border-[#3C3C3C] bg-[#202124] hover:border-[#DD0772]/50 hover:bg-[#2B2D31]"
                      }`}
                    >
                      <div className="flex items-center justify-between">
                        <p className="font-medium text-white">{album.name}</p>
                        {album.containsPosts && (
                          <span className="rounded bg-[#DD0772]/20 px-2 py-1 text-xs text-[#DD0772]">
                            {translations.added}
                          </span>
                        )}
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
        <div className="mx-6 mb-6 flex flex-col justify-between gap-3 sm:flex-row">
          <button
            type="button"
            className="inline-flex h-10 w-full items-center justify-center rounded-lg border border-[#3C3C3C] bg-[#202124] px-4 text-sm font-medium text-white transition-all duration-200 hover:bg-[#2B2D31] sm:w-auto"
            onClick={() => setShowAddToAlbumModal(false)}
          >
            {translations.cancel}
          </button>

          <button
            type="button"
            className="inline-flex h-10 w-full items-center justify-center rounded-lg border border-[#DD0772] bg-[#DD0772] px-4 text-sm font-medium text-white transition-all duration-200 hover:bg-[#DD0772]/90 focus:outline-none focus:ring-1 focus:ring-[#DD0772] disabled:cursor-not-allowed disabled:opacity-50 sm:w-auto"
            onClick={handleAddToAlbum}
            disabled={selectedAlbumIds.length === 0 || isLoading}
          >
            {isLoading && (
              <div className="mr-2 h-3.5 w-3.5 animate-spin rounded-full border-[1.5px] border-white/20 border-t-white" />
            )}
            {translations.add}
          </button>
        </div>
      </div>
    </Modal>
  );
}
